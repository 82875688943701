.sso-modal-container {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.sso-modal-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity;
  z-index: 1;
}

.sso-modal-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 29px;
  height: 29px;
  margin-top: -14px;
  margin-left: -14px;
}

.sso-modal-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.sso-modal-closer {
  @apply absolute float-right box-border cursor-pointer bg-white font-normal text-black opacity-100;
  text-shadow: 0 1px 0 white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: large;
  line-height: normal;
  top: 32px;
  right: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 2;
}

@media screen and (max-width: 992px) {
  .sso-modal-closer {
    font-size: small;
    top: 12px;
    right: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
