@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
  }
  /* Tailwind base has a rule to remove list-style */
  ol,
  ul {
    list-style-type: revert;
    list-style-position: outside;
    padding-left: revert;
  }
}

@layer utilities {
  .writing-mode-vertical-lr {
    writing-mode: vertical-lr;
  }
  .writing-mode-horizontal-tb {
    writing-mode: horizontal-tb;
  }

  input[type='search']::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

/* Cookiebot CMP branding */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

/* Layout */
#CybotCookiebotDialogDetailFooter {
  display: none !important;
}

/* Cookie detail list */
.CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
  display: none !important;
}

/* Cookies categories tabs */
#CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified {
  display: none !important;
}

/* Cookie category toggle */
.CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
  display: none !important;
}

/* Cookie count */
.CybotCookiebotDialogDetailBulkConsentCount {
  display: none !important;
}

/* Avoid pointer in category titles */
.CybotCookiebotDialogDetailBodyContentCookieContainerHeader,
#CybotCookiebotDialogDetailBodyContentCookieContainerPreference,
#CybotCookiebotDialogDetailBodyContentCookieContainerPreference label,
#CybotCookiebotDialogDetailBodyContentCookieContainerStatistics,
#CybotCookiebotDialogDetailBodyContentCookieContainerStatistics label,
#CybotCookiebotDialogDetailBodyContentCookieContainerAdvertising,
#CybotCookiebotDialogDetailBodyContentCookieContainerAdvertising label,
#CybotCookiebotDialogDetailBodyContentCookieContainerNecessary,
#CybotCookiebotDialogDetailBodyContentCookieContainerNecessary label {
  cursor: auto !important;
}

@font-face {
  font-family: 'SignPainter';
  src: url('/fonts/SignPainter.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
